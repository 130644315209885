export const getPaginateProps = (query = {} as any) => {
  const page = parseInt(query.page) || 1
  const take = parseInt(query.perPage) || 10
  let skip = 0
  if (page < 0) {
    skip = 0
  } else {
    skip = take * (page - 1)
  }

  return { skip, take, page }
}
