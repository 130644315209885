import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm'

@Entity({
  name: 'tags',
})
export class Tag {
  @PrimaryGeneratedColumn()
  id: number

  @Column({
    type: 'varchar',
  })
  name: string

  @CreateDateColumn({})
  createdAt: Date
  @UpdateDateColumn({})
  updatedAt: Date
  @DeleteDateColumn({ nullable: true })
  deletedAt: Date
}
