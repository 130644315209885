import { Tag } from 'reactstrap'
import {
  AfterLoad,
  BaseEntity,
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  JoinColumn,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm'
import { ILike } from 'util/ilike'
import { getPaginateProps } from 'util/paginate'
import { ArticleMetaTag } from './ArticleMetaTag'
import { Category } from './Category'
import { User } from './User'

export type ArticleStatus = 'Draf' | 'Pending' | 'Publikasi'

export enum ArticleStatusEnum {
  Draf,
  Pending,
  Publikasi,
}

export enum ContentType {
  Content,
  Video,
  Publication,
  Infographic,
  Gallery,
}

@Entity({
  name: 'articles',
})
export class Article extends BaseEntity {
  @PrimaryGeneratedColumn()
  id: number

  @Column({
    type: 'int8',
  })
  categoryId: number

  @OneToOne((type) => Category, { eager: true })
  @JoinColumn({ name: 'category_id' })
  category?: Category

  @Column({
    type: 'int8',
  })
  userId: number

  @OneToOne((type) => User, { eager: true })
  @JoinColumn({ name: 'user_id' })
  user: User

  @Column({
    type: 'int8',
  })
  publisherId: number

  @OneToOne((type) => User, { eager: true })
  @JoinColumn({ name: 'publisher_id' })
  published: User

  @Column({
    type: 'int4',
  })
  type: number

  @Column({
    type: 'text',
  })
  title: string

  @Column({
    type: 'text',
  })
  slug: string

  @Column({
    type: 'varchar',
    length: 100,
  })
  excerpt: string

  @Column({
    type: 'text',
  })
  content: string

  @Column({
    type: 'int4',
  })
  status: ArticleStatusEnum

  readableStatus: ArticleStatus

  @AfterLoad()
  renderReadableStatus?() {
    switch (this.status) {
      case 0:
        this.readableStatus = 'Draf'
        return
      case 1:
        this.readableStatus = 'Pending'
        return
      case 2:
        this.readableStatus = 'Publikasi'
        return
      default:
        this.readableStatus = 'Draf'
        return
    }
  }

  @Column({
    type: 'text',
  })
  featuredImgSrc: string

  @Column({
    type: 'int8',
  })
  visitCountCache: number

  @Column({
    type: 'int4',
  })
  isVisible: number

  @Column({
    type: 'text',
  })
  vendorSidShortLink: string

  @Column({
    type: 'int4',
  })
  templateId: number

  @Column({
    type: 'text',
  })
  bypassWriterName: string

  @Column({
    type: 'text',
  })
  bypassWriterBio: string

  @Column({
    type: 'int4',
  })
  isHideFeaturedImg: number

  @Column({
    type: 'date',
  })
  isFeaturedContent: Date

  @Column({
    type: 'text',
  })
  optBookAttachmentSrc: string

  @Column({
    type: 'text',
  })
  optVideoExternalLink: string

  @Column({
    type: 'date',
  })
  publishedAt?: Date

  @OneToMany((type) => ArticleMetaTag, (p) => p.article, {
    eager: true,
  })
  joinedTags: ArticleMetaTag[]

  tags: Tag[]
  // @AfterLoad()
  // parseTags?() {
  //   this.tags = this.joinedTags.map((item) => item.tag)
  // }

  @CreateDateColumn({ type: 'timestamp' })
  createdAt: Date
  @UpdateDateColumn({ type: 'timestamp' })
  updatedAt: Date
  @DeleteDateColumn({ type: 'timestamp', nullable: true })
  deletedAt: Date

  static async searchArticleByCategory({
    query,
    categorySlug = null,
    type = null,
    page = 1,
    perPage = 10,
  }) {
    const { page: _page, skip, take } = getPaginateProps({ page, perPage })

    const where = {
      status: 2,
      isVisible: 1,
      title: ILike(`%${query}%`),
    } as any

    if (categorySlug !== null) {
      const cat = await Category.getCategoryBySlug(categorySlug)
      where.categoryId = cat.id
    }

    if (type !== null) {
      where.type = type
    }

    const data = await this.getRepository().find({
      where,
      take,
      skip,
      cache: true,
      order: { createdAt: 'DESC' },
    })

    const total = await this.getRepository().count({ where })

    return {
      data: data.map(formatedArticle),
      page: _page,
      take,
      total,
      totalPage: Math.ceil(total / take),
    }
  }
}

export const formatedArticle = (item: Article) => {
  return {
    ...item,
    user: undefined,
    userId: undefined,
    publisherId: undefined,
    deletedAt: undefined,
    updatedAt: undefined,
    published: item.bypassWriterName
      ? { firstName: item.bypassWriterName, lastName: '' }
      : item.published
      ? {
          firstName: item.published.firstName,
          lastName: item.published.lastName,
        }
      : item.user
      ? {
          firstName: item.user.firstName,
          lastName: item.user.lastName,
        }
      : { firstName: 'Tanpa Nama', lastName: '' },
  }
}
