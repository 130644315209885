import {
  BaseEntity,
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  JoinColumn,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm'

@Entity({
  name: 'categories',
})
export class Category extends BaseEntity {
  @PrimaryGeneratedColumn()
  id: number

  @Column({
    type: 'int8',
  })
  parentId: number

  @OneToOne((type) => Category, {
    nullable: true,
  })
  @JoinColumn({ name: 'parent_id' })
  parent: Category

  @Column({
    type: 'text',
  })
  name: string

  @Column({
    type: 'text',
  })
  slug: string

  @Column({
    type: 'varchar',
    length: 255,
  })
  image: string

  @Column({
    type: 'text',
  })
  description: string

  @Column({
    type: 'int4',
  })
  isActive: number

  @Column({
    type: 'varchar',
    length: 255,
  })
  backgroundImage: string

  @OneToMany((type) => Category, (c) => c.parent)
  subCategory: Category[]

  @CreateDateColumn({})
  createdAt: Date
  @UpdateDateColumn({})
  updatedAt: Date
  @DeleteDateColumn()
  deletedAt: Date

  static getCategoryBySlug(categorySlug: string) {
    const query = this.createQueryBuilder('category')
    query.where('category.is_active = 1')
    query.where('category.slug = :categorySlug', { categorySlug })
    return query.getOne()
  }

  /**
   * Get All Available categories
   */
  static getAvailableCategories(categorySlug?: string) {
    const query = this.createQueryBuilder('category')

    if (categorySlug) {
      query.where('category.slug = :categorySlug', { categorySlug })
    } else {
      query.where('category.parent_id IS NULL')
    }

    if (categorySlug) {
      query.leftJoinAndSelect('category.parent', 'p')
      query.leftJoinAndSelect('p.parent', 'p2')
      query.leftJoinAndSelect('p2.parent', 'p3')
      query.leftJoinAndSelect('p3.parent', 'p4')
      query.leftJoinAndSelect('p4.parent', 'p5')
      query.leftJoinAndSelect('p5.parent', 'p6')
      query.leftJoinAndSelect('p6.parent', 'p7')
      query.leftJoinAndSelect('p7.parent', 'p8')
      query.leftJoinAndSelect('p8.parent', 'p9')
      query.leftJoinAndSelect('p9.parent', 'p10')
      query.leftJoinAndSelect('p10.parent', 'p11')
    }

    query
      .leftJoinAndSelect('category.subCategory', 'sb', 'sb.deletedAt IS NULL')
      .leftJoinAndSelect('sb.subCategory', 'sb2', 'sb2.deletedAt IS NULL')
      .leftJoinAndSelect('sb2.subCategory', 'sb3', 'sb3.deletedAt IS NULL')
      .leftJoinAndSelect('sb3.subCategory', 'sb4', 'sb4.deletedAt IS NULL')
      .leftJoinAndSelect('sb4.subCategory', 'sb5', 'sb5.deletedAt IS NULL')
      .leftJoinAndSelect('sb5.subCategory', 'sb6', 'sb6.deletedAt IS NULL')
      .leftJoinAndSelect('sb6.subCategory', 'sb7', 'sb7.deletedAt IS NULL')
      .leftJoinAndSelect('sb7.subCategory', 'sb8', 'sb8.deletedAt IS NULL')
      .leftJoinAndSelect('sb8.subCategory', 'sb9', 'sb9.deletedAt IS NULL')
      .leftJoinAndSelect('sb9.subCategory', 'sb10', 'sb10.deletedAt IS NULL')
      .leftJoinAndSelect('sb10.subCategory', 'sb11', 'sb11.deletedAt IS NULL')
      .cache(true)

    if (categorySlug) {
      return query.getOne()
    }
    return query.getMany()

    // .find({
    //   where: { parentId: IsNull() },
    //   // relations: ['subCategory'],
    // })
  }
}
