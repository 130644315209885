import {
  Column,
  CreateDateColumn,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm'
import { Article } from './Article'
import { Tag } from './Tag'

@Entity({
  name: 'article_meta_tags',
})
export class ArticleMetaTag {
  @PrimaryGeneratedColumn()
  id: number

  @Column({
    type: 'int8',
  })
  articleId: number

  @ManyToOne((type) => Article, (a) => a.joinedTags)
  article: Article

  @Column({
    type: 'int8',
  })
  tagId: number

  @OneToOne((type) => Tag, { eager: true })
  @JoinColumn()
  tag: Tag

  @CreateDateColumn({})
  createdAt: Date
  @UpdateDateColumn({})
  updatedAt: Date
}
